@use "fonts";
@use "colours" as colours;

/*********** Start - Basic styles ***********/
body,
html,
aku-root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: colours.$background;
  @extend %font-regular;
}

/*********** End - Basic styles ***********/