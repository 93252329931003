$background: #020202;
$menu-background: #5b5b5b;
$menu-background-hover: #3b3b3b;
$menu-background-separator: #0c5aa0;
$menu-text: #fff;
$menu-text-active: #f0b82a;
$overlay-text-background: rgba(0, 0, 0, 0.9);
$overlay-text-color: #fff;
$contact-dialog-border: $menu-background-separator;
$contact-dialog-background: #fff;
$contact-dialog-text: #000;
